.root {
    display: inline-flex;
    flex-grow: 0;
    align-items: center;
    padding: 5px;
    box-shadow: 0 0 2px 1px #C7C7C7;
    border-radius: 100px;
    font-size: 0.85vw;
}

.input {
    display: inline-block;
    width: 30px;
    outline: none;
    border: none;
    font-size: 0.85vw;
    text-align: center;
}
