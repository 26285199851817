.root {
    font-size: 14px;
    width: calc(100% - 10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5px 10px 5px;
    transition: 0.3s;
    cursor: pointer;
}

.root:hover {
    filter: brightness(0.8);
}

.audience {
    cursor: auto;
}

.audience:hover {
    filter: none;
}



.head {
    display: flex;
    align-items: center;
}

.title {
    width: 80%;
    overflow: hidden;
    text-align: left;
}

.state div {
    background-color: var(--primary-color);
}

.state[data-mode="pause"] div {
    background-color: var(--secondary-color);
}
