.root {
    background: white;
    display: inline-flex;
    flex-grow: 0;
    box-shadow: 0 0 2px 1px #C7C7C7;
    border-radius: 100px;
    width: 100%;
}

.input {
    display: inline-block;
    width: 100%;
    outline: none;
    border: none;
    font-size: 14px;
}
