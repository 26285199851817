.root {
    width: max(20vw, 250px);
    height: calc(100vh - 200px);
    flex-shrink: 0;
    flex-grow: 1;
    flex-direction: column;
    z-index: 5;
}

.list {
    flex: 1;
    overflow: auto;
    height: 100%;
    background-color: white;
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;
    height: 50px;
    margin: 10px 0;
    font-size: 20px;
    padding: 0 20px;
    cursor: pointer;
    transition: 0.3s;
}

.item div {
    display: flex;
    align-items: center;
}

.item:hover {
    background-color: #e6e6e6;
}

.info {
    fill: gray;
}

.info:hover {
    fill: var(--color-primary);
}

.tutorialLink {
    display: block;
    text-align: center;
}

.tutorialLink a {
    color: var(--color-primary);
}

.shrunk {
    position: absolute;
    left: -300px;
    top: 80px;
    width: 300px;
    display: flex;
    z-index: 5;
    transition: 0.3s;
}

.shrunk:hover {
    left: 0;
}

.shrunkRoot {
    padding: 20px 10px;
    width: 300px;
    background-color: white;
    flex-flow: column;
    height: calc(100vh - 280px);
    flex-shrink: 0;
}

.shrunkLabel {
    position: absolute;
    left: 100%;
    height: 130px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-lr;
    background-color: var(--color-primary);
    color: white;
    border-radius: 0 10px 10px 0;
}
