.root {
    display: flex;
    justify-content: stretch;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.hostPart {
    flex: 1;
    position: relative;
    height: 100vh;
}

.hostPart {
    background-color: var(--color-primary);
}

.audiencePart {
    flex: 1;
    position: relative;
    height: 100vh;
}

.centerBlock {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.logo {
    margin: 20px;
    width: 55px;
    height: 55px;
    fill: white;
}

.button {
    display: inline-block;
    padding: 8px 20px;
    width: 300px;
    border-radius: 100px;
    cursor: pointer;
    font-size: 18px;
    transition: 0.3s;
}

.start {
    color: var(--color-primary);
    background-color: white;
    border: 3px solid white;
}

.start:hover {
    color: white;
    background-color: var(--color-primary);
}

.join {
    color: white;
    background-color: var(--color-primary);
    border: 3px solid var(--color-primary);
}

.join:hover {
    color: var(--color-primary);
    background-color: white;
}

.primary div {
    background-color: white;
    border: var(--color-primary) 2px solid;
}

.incorrectContent {
    font-size: 20px;
}

.incorrectContent h1 {
    margin: 0 0 10px 0;
    font-size: 36px;
    color: var(--color-primary);
}
