.title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
    margin-bottom: 10px;
}

.votesDisplay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.forText {
    color: #1989FF;
}

.againstText {
    color: #FFAE65;
}

.abstainText {
    color: #9B9B9B;
}

.actionTab {
    display: flex;
    justify-content: space-around;
}

.button {
    border: 3px solid;
    padding: 2px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.1s;
}

.passed {
    border-color: #1EBE40;
    color: #1EBE40;
    background-color: white;
}

.passed:hover {
    color: white;
    border-color: #1EBE40;
    background-color: #1EBE40;
}

.passed.active {
    color: white;
    background-color: #1EBE40;
}

.failed {
    border-color: #E91818;
    color: #E91818;
    background-color: white;
}

.failed:hover {
    color: white;
    border-color: #E91818;
    background-color: #E91818;
}

.failed.active {
    color: white;
    background-color: #E91818;
}

.form {
    margin-top: 40px;
}

.row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

.vacantHint {
    color: gray;
}

.hereHref {
    color: var(--color-primary);
    text-decoration: underline;
    cursor: pointer;
}
