.title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
    margin-bottom: 10px;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.button {
    font-size: 35px;
    cursor: pointer;
}

.timeLabel {
    font-size: 20px;
}

.bottomBar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.moreTimeButton {
    position: relative;
    padding: 10px 0;
    margin: 0 5px;
    cursor: pointer;
    color: var(--color-primary);
}

.moreTimeButton:hover {
    color: var(--color-secondary);
}

.moreTimeButton .tooltipFrame {
    opacity: 0;
    pointer-events: none;
}

.moreTimeButton:hover  .tooltipFrame {
    opacity: 1;
    pointer-events: auto;
}

.form {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
