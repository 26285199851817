.item {
    display: flex;
    align-items: center;
    margin: 10px 10px;
    border-radius: 5px;
    padding: 15px;
    filter: brightness(1); 
    transition: 0.3s;
    cursor: pointer;
}

.item:hover {
    filter: brightness(.8); 
}

.title {
    width: 180px;
    overflow: hidden;
    text-align: left;
}

.head {
    display: flex;
    align-items: center;
}

.list {
    max-height: 60vh;
    overflow: auto;
}

.actionBar {
    display: flex;
    justify-content: flex-end;
}
