.root {
    display: flex;
    width: 100%;
    padding: 40px;
}

.splitted {
    flex-grow: 1;
    width: 50%;
    overflow: auto;
}

.itemList {
    display: flex;
    flex-wrap: wrap;
    max-height: 30%;
    overflow: auto;
}

.rollCallButton {
    border: 2px solid #007aff;
    display: inline-block;
    padding: 2px 25px;
    font-size: 20px;
    border-radius: 100px;
    transition: 0.3s;
    margin: 10px;
}

.rollCallButton:hover {
    background-color: #007aff;
    color: white;
}

.title {
    display: flex;
    align-items: center;
    color: #007aff;
    font-size: 28px;
    font-weight: 600;
}

.title div {
    color: white;
    border-radius: 100px;
    background: #007aff;
    font-size: 20px;
    font-weight: 300;
    padding: 5px 20px;
}

.result {
    position: relative;
    display: flex;
    width: 500px;
    flex-direction: column;
}

.sum {
    font-family: Robot;
    font-weight: 100;
    position: absolute;
    display: flex;
    align-items: center;
    font-size: 100px;
    top: 10px;
    right: -120px;
}

.sum span {
    font-weight: 300;
    margin-left: 20px;
    font-size: 30px;
}

.sum svg {
    width: 50px;
}

.resultItem {
    font-weight: 600;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 25px;
}

.resultItem span {
    font-weight: 300;
    display: inline-block;
    flex-basis: 110px;
    text-align: center;
    padding: 8px 20px;
    font-size: 18px;
    border-radius: 100px;
    color: white;
}

.timestamp {
    font-size: 20px;
}

.invert {
    transform: scaleX(-100%);
}
