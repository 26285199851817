.root {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.backCircular {
    position: absolute;
    left: 0;
    opacity: 0.3;
}

.processCircular {
    position: absolute;
    left: 0;
    transform: scale(-1, 1);
}
