.root {
    width: 100vw;
    height: 100vh;
}

.dashboardArea {
    display: flex;
    width: 100%;
    height: calc(100vh - 65px);
}

.panelArea {
    width: 100%;
    height: 100%;
    box-shadow: 2px 1px 2px 1px #BDBDBD;
}

.expiredContent {
    font-size: 20px;
}

.expiredContent h1 {
    margin: 0 0 10px 0;
    font-size: 36px;
    color: var(--primary-color);
}
