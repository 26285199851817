.controlButton {
    width: 35px;
    cursor: pointer;
    position: absolute;
    padding: 5px;
    transition: 0.3s top, 0.3s right, 0.3s opacity;
    background-color: var(--color-primary);
}

.controlButton:hover {
    background-color: var(--color-secondary);
}

.editButton {
    top: 0;
    right: 0;
    border-radius: 5px;
}

.resetButton {
    top: 40px;
    right: 0;
    border-radius: 5px;
}

.doneButton {
    top: 0;
    right: 0;
    border-radius: 5px;
    z-index: 5;
}

.card {
    width: 100%;
    height: 100%;
}

.card .subordinate {
    opacity: 0;
}

.card:hover .subordinate {
    opacity: 1;
    cursor: pointer;
}

.card:hover .topBar {
    opacity: 0.6;
    height: 30px;
}

.mainView {
    margin-top: 0;
    width: 100%;
}

.warning {
    position: absolute;
    color: #FFD54B;
    bottom: 10px;
    left: 10px;
}

.triangle {
    position: absolute;
    right: 0;
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    background-color: var(--color-secondary);
    width: 20px;
    height: 20px;
    transition: 0.2s;
}