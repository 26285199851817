.module-root {
    position: absolute;
    padding: 5px;
    transition: 0.3s opacity;
    /* transition: 0.3s opacity, 0.3s left, 0.3s top; */
    text-align: center;
}

.slide{
    transition: 0.3s opacity, 0.3s left, 0.3s top;
}

.top-bar {
    z-index: 1;
    border-radius: 5px 5px 0px 0px;
    position: absolute;
    color: gray;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    text-align: center;
    background-color: #1c2f3f;
    opacity: 0;
    transition: 0.3s;
    overflow: hidden;
  }

.top-bar .drag-handler > svg { 
    fill: white; cursor: grab 
}

.top-bar .toolbox{
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
}

.module-card {
    position: relative;
    width: 100%;
    height: 100%;
}

.module-card .subordinate { opacity: 0; }
.module-card:hover .subordinate {  opacity: 1; cursor: pointer; }
.module-card:hover .topBar {  opacity: 0.6; height: 30px}
