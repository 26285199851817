.root {
    max-width: 120vh;
}

.tab {
    display: inline-flex;
    align-items: center;
    background: white;
    box-shadow: 0 0 4px 0 gray;
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 5px 10px 0 0;
    /* cursor: pointer; */
    transition: 0.3s;
    font-size: 12px;
    height: 30px;
}

.tab * {
    font-size: 12px;
}

.editInput {
    outline: none;
    border: none;
    box-shadow: inset 0 0 1px gray;
    padding: 4px 2px;
}


.iconBtn {
    cursor: pointer;
}

.iconBtn:hover {
    background-color: lightgray;
    border-radius: 3px;
}

.addTab {
    cursor: pointer;
}

.addTab:hover {
    background-color: lightgray;
}



.disabled {
    opacity: 0;
    pointer-events: none;
    cursor: default;
}
