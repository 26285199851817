.root {
    display: inline-flex;
    align-items: center;
    color: var(--color-primary);
    cursor: pointer;
    position: relative;
    font-size: 14px;
    text-align: left;
}

.list {
    position: absolute;
    top: 30px;
    background: white;
    width: 100%;
    box-shadow: 0 0 4px 0 gray;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
    z-index: 5;
}

.list--open {
    opacity: 1;
    pointer-events: auto;
}

.item {
    width: 100%;
    padding: 5px;
}

.item:hover {
    background: var(--color-primary);
    color: white;
}
