.root {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-left: 0;
    gap: 20px;
}

.card {
    display: inline-block;
    flex: 1 1 50%;
    width: 100%;
    height: 100%;
    padding: 30px;
}

.list {
    overflow: auto;
    height: calc(100% - 60px);
}

.item {
    display: flex;
    align-items: center;
    margin: 10px;
    border-radius: 5px;
    padding: 15px;
    transition: 0.3s;
    cursor: pointer;
}

.item:hover {
    filter: brightness(0.8) !important;
}

.selectedTitle {
    font-size: 30px;
    font-weight: 500;
    margin: 10px;
    display: flex;
    justify-content: space-between;
}
