.root {
    width: 100vw;
    height: 100vh;
}

.displayArea {
    display: flex;
    width: 100%;
    height: calc(100% - 70px);
    justify-content: center;
    align-items: center;
}

.panelArea {
    width: 100%;
    height: 100%;
    box-shadow: 2px 1px 2px 1px #BDBDBD;
}
