.root {
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  transition: 0.3s opacity;
  z-index: 50;
}

.backdrop {
  width: 100vw;
  height: 100vh;
  background-color: #1111115E;
}

.modal {
  display: block;
  padding: 20px;
  position: absolute;
  max-height: calc(100vh - 100px);
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
