.root {
    position: relative;
    flex-shrink: 0;
    box-shadow: 0 0 4px 0 gray;
    background-color: white;
}

.debugInfo {
    background-color: #CCE5F0;
}
