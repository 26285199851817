.root {
    position: absolute;
    filter: drop-shadow(0 0 2px gray);
    transition: 0.3s;
}

.container {
    display: inline-block;
    padding: 8px;
    background: white;
    border-radius: 5px;
}

.top {
    bottom: 70%;
    left: 50%;
    transform: translateX(-50%);
}
