.root {
    display: inline-block;
    border-radius: 100px;
    padding: 8px 25px;
    font-size: 16px;
    transition: 0.3s;
    border: 2px solid;
}

.root:hover {
    background-color: white;
}

.root:disabled {
    background-color: lightgray;
    color: white;
    cursor: not-allowed;
    border-color: lightgray;
}

.root:not(:disabled) {
    background-color: #007aff;
    color: white;
    cursor: pointer;
    border-color: #007aff;
}

.root:not(:disabled):hover {
    background-color: white;
    color: #007aff;
}
