
:root{
  --color-primary: #1989FF;
  --color-secondary: #FFAE65;
  --color-dark: #1c2f3f;
}

html,
body {
  user-select: none;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.defaultView {
  text-align: center;
  width: 100%;
}

.editView {
  border-radius: 5px;
  padding: 10px 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  transition: 0.3s opacity;
  pointer-events: none;
  z-index: 3;
}

.editView.active {
  opacity: 1;
  pointer-events: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #e0e0e0;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  transition: 0.2s;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: black;
}

.shadow{
  filter:drop-shadow(0 1px 1px gray)
}