.root {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 10;
}

.button {
    cursor: pointer;
    position: relative;
    width: 100px;
    height: 100px;
    background: var(--color-primary);
    border-radius: 100%;
    border: 5px solid #AFE2FF;
    transition: 0.3s;
}

.button:hover {
    border: 10px solid var(--color-primary);
    background: white;
}

.button:hover > svg {
    fill: var(--color-primary);
}

.button:hover > span {
    opacity: 0;
}

.button:hover > div {
    opacity: 1;
    pointer-events: auto;
}

.button > svg {
    fill: white;
    transition: 0.3s;
}

.shelfIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.count {
    border: 3px solid #AFE2FF;
    position: absolute;
    color: white;
    top: -15px;
    right: -15px;
    border-radius: 20px;
    background-color: var(--color-primary);
    padding: 5px 10px;
    transition: 0.3s;
}

.touchArea {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 400px;
    padding: 60px 10px;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
}

.list {
    background: white;
    padding: 10px 5px;
    box-shadow: 0 0 5px 0 gray;
    border-radius: 10px;
}

.listTitle {
    text-align: center;
    font-size: 20px;
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin: 10px;
    height: 50px;
    color: black;
    background-color: #FFFFFF;
    transition: 0.3s;
}

.item:hover {
    background-color: #E6E6E6;
}

.item .leftPart {
    display: flex;
    align-items: center;
}
