.root {
    display: flex;
    align-items: center;
}

.checkRoot {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-width: 4px;
    border-style: solid;
    border-radius: 5px;
}

.check {
    display: inline-block;
    border-radius: 2px;
}
