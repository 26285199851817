.root {
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
}

.title {
    display: flex;
    font-size: 30px;
    font-weight: 500;
    margin: 10px;
    align-items: center;
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
    border-radius: 5px;
    padding: 15px;
}

.nameText {
    overflow: hidden;
    text-align: left;
}

.head {
    width: 50%;
    display: flex;
    align-items: center;
}

.list{
    width: 100%;
    overflow-y: auto;
}
