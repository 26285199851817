.title {
    overflow: hidden;
    /* textOverflow: "ellipsis"; */
    white-space: wrap;
    font-size: 20px;
}

.form {
    margin-top: 10px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.hereHref {
    color: var(--color-primary);
    text-decoration: underline;
    cursor: pointer;
}

.visibility {
    cursor: pointer;
    transition: 0.3s;
}

.topicInput {
    width: 100%;
    resize: none;
    outline: none;
    border: none;
    box-shadow: 0 0 4px 0 gray;
    border-radius: 5px;
    padding: 5px;
}

.submitter {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.touchable {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 100px;
    cursor: pointer;
    transition: 0.3s;
    background-color: white;
    filter: brightness(1);
}

.touchable:hover {
    filter: brightness(0.9);
}

.topic {
    font-weight: 500;
}
