/* FILEPATH: /Users/lyon/Documents/projects/dev-munative-lite-app/src/components/composed/StatusDropDown/style.module.css */

.root {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    height: 100px;
    padding: 10px;
}

.root > * {
    transition: 0.3s;
}

.root .menu {
    z-index: 45;
    position: absolute;
    top: 0;
    left: 50px;
    height: 0;
    overflow: hidden;
}

.root:hover .menu {
    height: 300px;
}

.list {
    background-color: #FFFFFFD3;
    height: 200px;
    width: 300px;
    padding-left: 10px;
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: space-around;
    transition: 0.3s;
}

.item {
    align-items: center;
    display: flex;
}

.item span {
    font-size: 28px;
    padding-left: 20px;
}

.title {
    font-size: 28px;
}
