.root {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 10px 10px 20px 40px;
    gap: 20px;
}

.display {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10px 40px 20px 40px;
}

.expand {
    display: flex;
    flex-direction: column;
    width: fit-content;
    overflow: hidden;
    padding: 3px;
    align-items: flex-start;
}

.col {
    justify-content: center;
    display: flex;
    width: 100%;
}
