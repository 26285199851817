.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.input {
    margin: 30px 20px 10px 20px;
    width: calc(100% - 40px);
    height: calc(100% - 30px);
    resize: none;
    outline: none;
    border-radius: 20px;
    padding: 25px;
    border: none;
    box-shadow: 0 0 2px 0 gray;
    font-size: 20px;
}
