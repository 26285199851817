.root {
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-lr;
    margin-bottom: 20px;
    width: 45px;
    height: 150px;
    background-color: var(--color);
    opacity: var(--is-active);
    color: white;
    transition: 0.3s;
    border-radius: 0 5px 20px 0;
    cursor: pointer;
    box-shadow: var(--is-active) 2px 0 2px 1px #bdbdbd;
}
