.title {
    font-size: 30px;
    margin-bottom: 20px;
}

.controllers {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.current {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.current .process {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.overlay {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100px;
    background: #46464649;
}

.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 100px;
    box-shadow: 0 0 3px 0 gray;
    margin: 0 10px;
    cursor: pointer;
}

.button:hover {
    filter: brightness(0.8);
}

.buttonAudience:hover {
    filter: none;
    cursor: auto;
}

.stance {
    position: absolute;
    bottom: calc(40% + 40px);
    left: 2px;
    font-size: 12px;
}

.speakerTime {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.totalTime {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.totalTime span {
    color: #007aff;
}

.editButton {
    font-size: 16px;
    color: #007aff;
    cursor: pointer;
    text-decoration: underline;
}

.list {
    padding: 10px 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 300px);
}

.inputsArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input {
    display: inline-block;
    font-size: 16px;
    border-radius: 100px;
    background-color: white;
    overflow: hidden;
    box-shadow: 0 0 2px 0 gray;
    max-width: 250px;
    flex-grow: 1;
    padding: 10px 20px;
    outline: none;
    border: none;
}

.timeInput {
    display: inline-block;
    font-size: 16px;
    border-radius: 100px;
    background-color: white;
    overflow: hidden;
    box-shadow: 0 0 2px 0 gray;
}

.timeInput .head {
    display: inline-block;
    padding: 10px;
    background-color: #007aff;
    color: white;
}

.timeInput input {
    width: 50px;
    font-size: 16px;
    display: inline-block;
    padding: 10px;
    outline: none;
    border: none;
}

.timeInput .label {
    display: inline-block;
    padding: 10px;
    color: #007aff;
}

.selectorRoot {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 40px;
    padding-left: 0;
}

.card {
    display: inline-block;
    flex: 1 1 50%;
    width: 100%;
    height: 100%;
    padding: 30px;
}

.selectList {
    overflow: auto;
    height: calc(100vh - 400px);
    padding-top: 3px;
}

.item {
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    margin: 0 10px 10px 10px;
    border-radius: 5px;
    padding: 15px;
    filter: brightness(1);
    transition: 0.3s;
    position: relative;
    background: white;
    box-shadow: 0 0 4px 2px lightgray;
    cursor: pointer;
}

.item:hover {
    filter: brightness(0.8);
}

.selected:hover {
    filter: brightness(1);
    cursor: move;
}

.yielded {
    position: absolute;
    z-index: 10;
    bottom: 0;
    right: 0;
}

.modalTitle {
    font-size: 28px;
    display: flex;
    justify-content: space-between;
}
