@keyframes shake {
  0% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(10px);
  }

  50% {
    transform: translateX(0px);
  }

  75% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0px);
  }
}

.shake {
  animation: shake 0.2s infinite;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 4px;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 10px;
  animation-delay: -0.36s;
}

.lds-facebook div:nth-child(2) {
  left: 16px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(3) {
  left: 22px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(4) {
  left: 28px;
  animation-delay: 0s;
}

@keyframes lds-facebook {
  0% {
    top: 0px;
    height: 40px;
  }

  50%,
  100% {
    top: 10px;
    height: 22px;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.lds-ellipsis div {
  position: absolute;
  top: 8px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 2px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 2px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 14px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(6px, 0);
  }
}