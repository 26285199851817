.title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
}

.titleInput {
    width: 100px;
}

.url {
    width: 65%;
    display: flex;
    align-items: center;
}

.url a {
    text-decoration: underline;
    color: var(--color-primary);
    font-size: 20px;
    overflow: hidden;
    text-decoration-color: var(--color-primary);
}

.url svg {
    flex-shrink: 0;
}

.link {
    display: block;
    color: var(--color-primary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: underline;
}
