.root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 65px;
}

.roomName {
    font-size: 36px;
    flex-grow: 1;
    padding: 0 40px;
}

.logo {
    width: 50px;
    height: 50px;
    fill: var(--color-primary);
}

.sharingDisplay {
    font-size: 18px;
    margin-right: 10px;
}

.roomCode {
    color: var(--color-primary);
    cursor: pointer;
    padding: 5px 10px;
    transition: 0.2s;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap:2px;

}

.roomCode:hover {
    background-color: rgba(147, 224, 255, 0.312);
}

.feedbackLink {
    color: var(--color-dark);
    text-decoration: underline;
}
