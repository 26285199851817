.root {
    padding: 140px 40px 0 40px;
    height: 100%;
    width: 100%;
}

.list {
    margin-top: 20px;
    overflow: auto;
}

.sumRow {
    display: flex;
    height: 40px;
}

.title {
    display: flex;
    align-items: center;
    width: 250px;
    flex-shrink: 0;
}

.item {
    margin: 0 10px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}


.editButton {
    display: inline-flex;
    position: absolute;
    top: 50px;
    right: 50px;
    background-color: var(--color-primary);
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    cursor: pointer;
    transition: 0.3s;
}

.editButton:hover {
    background-color: var(--color-secondary);
}

.editButtonEditing {
    display: inline-flex;
    position: absolute;
    top: 50px;
    right: 50px;
    background-color: var(--color-secondary);
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    cursor: pointer;
    transition: 0.3s;
}

.editButtonEditing:hover {
    background-color: var(--color-primary);
}

.header {
    display: flex;
    padding-left: 250px;
}

.headerItem {
    text-align: center;
    flex: 1;
    font-size: 18px;
}

.input {
    width: 180px;
}

.row {
    display: flex;
    height: 80px;
}

.rowItem {
    margin: 0 10px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rowTitle {
    display: flex;
    align-items: center;
    width: 250px;
    flex-shrink: 0;
}

.rowInput {
    display: inline-block;
    max-width: 100px;
    margin: 0 5px;
    text-align: center;
}

.rowInput input {
    text-align: center;
}