.title {
    text-align: center;
    font-size: 24px;
}

.titleInput {
    width: 100px;
}

.individualIcon {
    width: 20px;
}

.submitter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.touchable {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 100px;
    cursor: pointer;
    transition: 0.3s;
    background-color: white;
    filter: brightness(1);
}

.touchable:hover {
    filter: brightness(0.9);
}

.input {
    width: calc(100% - 40px);
    height: calc(100% - 100px);
    resize: none;
    outline: none;
    border-radius: 10px;
    padding: 15px;
    border: none;
    box-shadow: 0 0 2px 0 gray;
    font-size: 14px;
}

.type {
    color: var(--primary-color);
}

.editTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}
