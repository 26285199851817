.root {
    width: 100%;
    height: 100%;
    display: flex;
}

.leftCol {
    padding: 20px;
    flex: 0 0 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.title {
    font-size: 26px;
    font-weight: 600;
}

.nameArea {
    display: block;
    width: 100%;
    padding: 20px;
}

.templateArea {
    display: block;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 20px;
    overflow: hidden;
}

.templateList {
    margin-top: 10px;
    height: calc(100% - 50px);
    overflow: auto;
    padding: 0 5px;
}

.templateButton {
    display: block;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
    width: 100%;
}

.logoutArea {
    display: block;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 20px;
}

.rightCol {
    flex: 0 0 70%;
    height: 100%;
}